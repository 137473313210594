import type { RouteRecordRaw } from 'vue-router';

const LoginPageVue = () => import('@/components/auth/LoginPage.vue');
function RegisterMemberPageVue() {
  return import('@/components/auth/RegisterMemberPage.vue');
}
function RegisterClubPageVue() {
  return import('@/components/auth/RegisterClubPage.vue');
}

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: LoginPageVue,
    meta: { requiresAuth: false, hideIfAuth: true },
  },
  {
    path: '/register/member',
    name: 'register-member',
    component: RegisterMemberPageVue,
    meta: { requiresAuth: false },
  },
  {
    path: '/register/club',
    name: 'register-club',
    component: RegisterClubPageVue,
    meta: { requiresAuth: false },
  },
];
