import { http } from '@/core';
import type {
  Club,
  ClubInfoFormPayload,
  LoginForm,
  Member,
  MemberInfoFormPayload,
  RegisterClubFormPayload,
  RegisterMemberFormPayload,
  User,
  UserAccountFormPayload,
  UserSecurityFormPayload,
} from '@/data/users/models';

// Login user
export async function loginUser(payload: LoginForm) {
  return await http
    .post('_allauth/app/v1/auth/login', { json: payload })
    .json<any>();
}

// Logout user
export async function logoutUser() {
  return await http.delete('_allauth/app/v1/auth/session').json<void>();
}

// Register member
export async function registerMember(payload: RegisterMemberFormPayload) {
  return await http
    .post('_allauth/app/v1/auth/signup', { json: payload })
    .json<void>();
}

// Register club
export async function registerClub(payload: RegisterClubFormPayload) {
  return await http
    .post('_allauth/app/v1/auth/signup', { json: payload })
    .json<void>();
}

// Get current user details
export async function getCurrentUser() {
  return await http.get('me/').json<User>();
}

// Set email
export async function setEmail(payload: UserAccountFormPayload) {
  return await http
    .post('auth/users/set_email/', { json: payload })
    .json<void>();
}

// Set password
export async function setPassword(payload: UserSecurityFormPayload) {
  return await http
    .post('auth/users/set_password/', { json: payload })
    .json<void>();
}

// Update member info
export async function updateMemberInfo(payload: MemberInfoFormPayload) {
  return await http.patch('me/member/', { json: payload }).json<Member>();
}

// Update club info
export async function updateClubInfo(payload: ClubInfoFormPayload) {
  return await http.patch('me/club/', { json: payload }).json<Club>();
}
